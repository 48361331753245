/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
exports.shouldUpdateScroll = () => {
    if (window.__navigatingToLink === true) {
        return [0, 0];
    }
    return true;
};

exports.onRouteUpdate = () => {
    if (window.location != null && (window.location.pathname === '/' || window.location.pathname === '/compleet' || window.location.pathname === '/compleet/' || window.location.pathname === '/voor-wie' || window.location.pathname === '/voor-wie/')) {
        require("./src/assets/styles/smoothScroll.css");
    }
    window.__navigatingToLink = false;
};